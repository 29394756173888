<template>
  <div class="dashboard-filters">
    <div class="dashboard-filters__line">
      <div
          v-for="(item, i) of ['cpc', 'organic']"
          :key="i"
          class="dashboard-filters__item"
      >
        <v-filter-traffic
            :title="`Набор каналов ${i + 1}`"
            filter-name="medium"
            :date-filters="dateFilters.one"
            :url-filters="urlFilters"
            :url-name="`medium_${i + 1}`"
            :needs-data="i === 0"
            :default-value="item"
        />
      </div>

      <div class="dashboard-filters__item _right">
        <s-daterangepicker-comparison
            id="filters_date"
            v-model="filters.date"
            v-bind="{
              placeholder: 'Выберите дату',
              dynamicWidthDrop: true,
              dropPosition: 'bottom-end',
              availablesTo: availableDateTo,
              availablesFrom: availableDateFrom,
              comparisonEnabled: false
            }"
            @input="updateFilters('date', $event)"
        />
      </div>
    </div>

    <div class="dashboard-filters__line">
      <div class="dashboard-filters__item">
        <v-traffic-source-toggle :value="urlFilters.traffic_source" />
      </div>

      <div class="dashboard-filters__item">
        <v-filter-region
            :regions="regions"
            :value="filters.region_id"
            @input="updateFilters('region_id', $event)"
        />
      </div>

      <div class="dashboard-filters__item">
        <s-select
            id="filters_device"
            v-model="filters.is_mobile"
            v-bind="{
              options: devices,
              insideLabel: 'Устройство',
              placeholder: 'Выберите устройство',
              minWidth: '270px',
              clearButton: true,
              appendToBody: false
            }"
            @input="updateFilters('is_mobile', $event)"
        />
      </div>

      <div class="dashboard-filters__item">
        <v-filter-traffic
            title="Источник"
            filter-name="source"
            :date-filters="dateFilters.one"
            :url-filters="urlFilters"
        />
      </div>

      <div class="dashboard-filters__item _right">
        <v-button
            :name="showChart ? 'Скрыть график' : 'Показать график'"
            type="base"
            size="small"
            padding-right="12px"
            padding-left="12px"
            @click="$emit('toggle-chart')"
        />
      </div>
    </div>

    <div class="dashboard-filters__line">
      <div class="dashboard-filters__item">
        <v-select-tree-segments
            v-if="!isMixedSegments"
            v-bind="{
              filters,
              insideLabel: 'Сегменты',
              placeholder: 'Выберите сегмент'
            }"
            @update-url="updateUrl"
            @loaded="$emit('finish-filters-loading')"
        />
        <v-select-tree-mixed-segments
            v-if="isMixedSegments"
            v-bind="{
              filters,
              insideLabel: 'Сегменты',
              placeholder: 'Выберите сегмент'
            }"
            @update-url="updateUrl"
            @loaded="$emit('finish-filters-loading')"
        />
      </div>
    </div>

    <div class="dashboard-filters__line">
      <div class="dashboard-filters__item">
        <v-select-tree-metric
            v-bind="{
              metrics,
              metricActive: filters.metric,
              multiple: true,
              activeMetrics
            }"
            @update-url="updateUrl"
        />
      </div>

      <div class="dashboard-filters__item _right">
        <v-select-tree-segments
            v-if="!isMixedSegments"
            v-bind="{
                filters,
                insideLabel: 'Контрольная группа',
                placeholder: 'Выберите сегмент',
                multiple: false,
                disabled: filters.all_site,
                filterName: 'control_group',
                needsData: false,
                dropPosition: 'bottom-end'
              }"
            @update-url="updateUrl"
        />
        <v-select-tree-mixed-segments
            v-if="isMixedSegments"
            v-bind="{
                filters,
                insideLabel: 'Контрольная группа',
                placeholder: 'Выберите сегмент',
                multiple: false,
                disabled: filters.all_site,
                filterName: 'control_group',
                needsData: false,
                dropPosition: 'bottom-end'
              }"
            @update-url="updateUrl"
        />
      </div>

      <div class="dashboard-filters__item">
        <div class="toggle-filters">
          <div class="toggle-filters__value">
            <v-radio-toggle
                v-model="filters.all_site"
                dataforce
                @input="updateFilters('all_site', String($event))"
            />
          </div>
          <div class="toggle-filters__label">Весь сайт</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CONSTANTS from '@/const'
import ROUTE_NAME from '@/router/routeName'

import trafficSourceMixin from '@/mixins/trafficSourceMixin'

import selectTreeSegments from '@/services/components/selectTreeSegments'
import selectTreeMixedSegments from '@/services/components/selectTreeMixedSegments'
import filterTraffic from '@/services/components/filterTraffic'
import selectTreeMetric from '../selectTreeMetric'
import filterRegion from '@/services/components/filterRegion'

export default {
  mixins: [trafficSourceMixin],

  components: {
    'v-select-tree-segments': selectTreeSegments,
    'v-select-tree-mixed-segments': selectTreeMixedSegments,
    'v-filter-traffic': filterTraffic,
    'v-select-tree-metric': selectTreeMetric,
    'v-filter-region': filterRegion
  },

  props: {
    urlFilters: {
      type: Object,
      default: () => ({})
    },

    showChart: {
      type: Boolean,
      default: true
    },

    metrics: {
      type: Array,
      default: () => ([])
    },

    devices: {
      type: Array,
      default: () => ([])
    },

    dateFilters: {
      type: Object,
      default: () => ({})
    },

    regions: {
      type: Array,
      default: () => ([])
    },

    activeMetrics: {
      type: Object,
      default: () => ({})
    },

    availableDateTo: {
      type: Number
    },

    availableDateFrom: {
      type: Number
    },

    isMixedSegments: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      ROUTE_NAME,
      filters: {}
    }
  },

  watch: {
    urlFilters () {
      this.setFilters()
    }
  },

  created () {
    this.setFilters()
  },

  methods: {
    updateFilters(filter, value) {
      switch (filter) {
        case 'date':
          let query = {
            period_one_date_from: this.$moment.utc(+value.one.from).format(CONSTANTS.DATE_FORMAT.BASE_FORMAT),
            period_one_date_to: this.$moment.utc(+value.one.to).format(CONSTANTS.DATE_FORMAT.BASE_FORMAT),
            period_two_date_from: undefined,
            period_two_date_to: undefined
          }

          if (value.two.from && value.two.to) {
            query.period_two_date_from = this.$moment.utc(+value.two.from).format(CONSTANTS.DATE_FORMAT.BASE_FORMAT)
            query.period_two_date_to = this.$moment.utc(+value.two.to).format(CONSTANTS.DATE_FORMAT.BASE_FORMAT)
          }

          this.updateUrl(query)
          break

        case 'is_mobile':
          if (value === 0) {
            this.updateUrl({[filter]: String(value)})
          } else {
            this.updateUrl({[filter]: value})
          }
          break

        default:
          this.updateUrl({[filter]: value})
      }
    },

    updateUrl(query) {
      this.$emit('update-url', query)
    },

    setFilters() {
      this.$set(this, 'filters', {
        ...this.urlFilters
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.toggle-filters {
  display: flex;
  align-items: center;

  &__label {
    margin-left: 10px;
  }

  &__value {
    line-height: 20px;
    font-size: 14px;
    color: #455165;
  }
}
</style>
