<template>
  <vue-line :class="{'_control-group': controlGroup}">
    <vue-item>
      <span
          v-if="controlGroup"
          class="label"
      >
        Контрольная группа: </span>
      {{ data.name }}
    </vue-item>
    <template v-for="metric in metrics">
      <template v-for="i of 2">
        <vue-item align="right">
          {{ data[`${metric.id}_${i}`] | formatCount }}{{ metric.additional }}
        </vue-item>
      </template>
      <vue-item align="right">
        <vue-element
            class="metric__diff"
            type="label"
            :data="data[`${metric.id}_diff`]"
            :label-note="metric.additional"
            locales="ru-RU"
        />
      </vue-item>
      <vue-item align="right">
        <template v-if="data[`${metric.id}_diff_prc`] || data[`${metric.id}_diff_prc`] === 0">
          {{ data[`${metric.id}_diff_prc`] | formatCount }}
        </template>
      </vue-item>
    </template>
    <vue-item align="center">
      <div
          v-if="!controlGroup"
          class="buttons"
      >
        <v-button
            size="small"
            type="text-base"
            padding-right="6px"
            padding-left="6px"
            :to="{query: routeCG.query}"
            v-tippy
            content="Сделать контрольной группой"
        >
          <icon
              name="kg"
              fill="#9d99aa"
          />
        </v-button>
        <v-button
            v-bind="{
            size: 'small',
            type: 'text-base',
            paddingLeft: '6px',
            paddingRight: '6px',
            to: { query: routeDelete.query },
            content: 'Убрать из сравнения'
          }"
            v-tippy
        >
          <icon
              v-bind="{
              name: 'delete',
              fill: '#9D99AA'
            }"
          />
        </v-button>
      </div>
    </vue-item>
  </vue-line>
</template>

<script>
import {generateRoute} from '@/function'

export default {
  name: 'comparisonTableRow',

  props: {
    data: {
      type: Object,
      default: () => ({})
    },

    metrics: {
      type: Object,
      default: () => ({})
    },

    urlFilters: {
      type: Object,
      default: () => ({})
    },

    controlGroup: {
      type: Boolean,
      default: false
    },

    segmentIsControlGroup: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    routeDelete () {
      let newRoute = generateRoute(this.$route)
      newRoute.query.catalog_ids = this.urlFilters.catalog_ids

      return this.deleteParam({
        route: newRoute,
        id: this.data.id
      })
    },

    routeCG () {
      let newRoute = generateRoute(this.$route)
      newRoute.query.control_group = this.data.id
      newRoute.query.all_site = 'false'
      newRoute.query.catalog_ids = this.urlFilters.catalog_ids
      return this.deleteParam({
        route: newRoute,
        id: this.data.id
      })
    }
  },

  methods: {
    deleteParam ({ route, id }) {
      try {
        let activeFilter = route.query.catalog_ids

        if (Array.isArray(activeFilter)) {
          let newSegmentsQuery = [...route.query.catalog_ids]
          const index = newSegmentsQuery.indexOf(String(id))
          if (index > -1) {
            newSegmentsQuery.splice(index, 1)
          }
          route.query.catalog_ids = newSegmentsQuery
        } else {
          route.query.catalog_ids = undefined
        }
      } catch (_) {
      }

      return route
    }
  }
}
</script>

<style
    scoped
    lang="scss"
>
.metric__diff._bg {
  width: 40%;
  min-width: fit-content;
  line-height: 20px;
  text-align: right;
  font-size: 12px;
}

.label {
  color: #9D99AA;
}

.table-line._control-group {
  .table-item {
    background: #F1F7FD !important;
  }
}

.buttons {
  display: flex;
  align-items: center;
  column-gap: 5px;
}
</style>
