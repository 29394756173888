<template>
  <v-preloader
      v-if="!rendered"
      static
      clear
  />
  <v-chart
      v-else
      ref="chart"
      v-model="chartData"
      lib="highcharts"
      dataforce
  />
</template>

<script>
const CHART_COLORS = ['#7059FF', '#01D358', '#FF7F63', '#32A4FF']
export default {
  name: 'comparisonChart',

  props: {
    items: {
      type: Array,
      default: () => ([])
    },

    metrics: {
      type: Object,
      default: () => ({})
    },

    channels: {
      type: Object,
      default: () => ({})
    },

    controlGroup: {
      type: Object,
      default: undefined
    }
  },

  data () {
    return {
      rendered: true
    }
  },

  computed: {
    chartData () {
      const self = this
      let series = []
      let i = 0
      let yAxisCount = 0

      for (let metric in this.metrics) {
        for (let j = 1; j <= 2; j++) {
          let data = {
            name: `${this.metrics[metric].name} (${this.channels[`channels${j}`].join(', ')})`,
            data: this.items.map(item => item[`${this.metrics[metric].id}_${j}`]),
            stack: this.metrics[metric].id,
            color: CHART_COLORS[i],
            yAxis: yAxisCount,
            metric: this.metrics[metric],
            channels: this.channels[`channels${j}`].join(', ')
          }

          if (this.controlGroup) {
            data.data.push(this.controlGroup[`${this.metrics[metric].id}_${j}`])
          }

          series.push(data)
          i++
        }
        yAxisCount++
      }

      // series = series.map((item, i) => {
      //   let newItem = {...item, id: i}
      //
      //   if (i % 2 !== 0) {
      //     newItem.linkedTo = ':previous'
      //   }
      //
      //   return newItem
      // })

      let categories = [...this.items.map(item => item.url_title || item.name)]

      if (this.controlGroup) {
        categories = [...categories, 'Контрольная группа']
      }

      let yAxis = []

      for (let metric in this.metrics) {
        yAxis.push({
          labels: {
            formatter: function () {
              return self.metrics[metric].additional
                    ? this.value.toFixed(2).toLocaleString() + self.metrics[metric].additional
                    : this.value.toLocaleString()
              }
            },
            gridLineDashStyle: 'Dash'
          })
      }

      if (yAxis.length > 1) {
        yAxis[1].opposite = true
      }

      return {
        chart: {
          type: 'column',
          spacingRight: 25
        },

        xAxis: {
          type: 'category',
          categories,
          labels: {
            autoRotation: undefined,
            style: {
              color: '#2D3646'
            }
          }
        },

        yAxis,

        plotOptions: {
          column: {
            stacking: 'normal',
            borderWidth: 0,
          }
        },

        tooltip: {
          useHTML: true,
          headerFormat: '<p style="border-bottom: 1px solid #E0E6F0; padding-bottom: 10px; margin-bottom: 10px"><b>{point.key}</b></p>',
          pointFormatter: function () {
            let value = this.series.userOptions.metric.additional
                ? this.y.toFixed(2).toLocaleString() + this.series.userOptions.metric.additional
                : this.y.toLocaleString()

            return `
            <div style="line-height: 18px;">
                <span style="font-size: 16px; color:${this.color};">\u25CF</span> ${this.series.userOptions.metric.name} (${this.series.userOptions.channels}): <b>${value}</b>
            </div>
          `
          }
        },

        exporting: {
          enabled: false
        },

        series
      }
    }
  },

  watch: {
    controlGroup () {
      this.rerenderChart()
    }
  },

  mounted () {
    if (this.$refs.chart) {
      this.$refs.chart.$refs.highcharts.chart.reflow()
    }
  },

  methods: {
    rerenderChart () {
      this.rendered = false

      setTimeout(() => {
        this.rendered = true
      }, 1000)
    }
  }
}
</script>

<style scoped>

</style>
