<template>
  <vue-table
      small-padding
      head-background
      fixed-bar
      fixed-head
      :offset-top="56"
      :hoverable-rows="false"
  >
    <template #colgroup>
      <colgroup>
        <col style="width: 20%">
        <col
            v-for="i in metricCellCount"
            :key="i + '-cell'"
        >
        <col style="width: 80px">
      </colgroup>
    </template>
    <template #head>
      <vue-line>
        <vue-item
            rowspan="2"
            valign="bottom"
        >
          Сегменты
        </vue-item>
        <vue-item
            v-for="metric in metrics"
            :key="`${metric.id}_title`"
            colspan="4"
        >
          {{ metric.name }}
        </vue-item>
        <vue-item
            rowspan="2"
            align="center"
            valign="bottom"
        >
          Действия
        </vue-item>
      </vue-line>

      <vue-line>
        <template v-for="metric in metrics">
          <vue-item
              v-for="i of 2"
              :key="`${metric.id}_${i}`"
              align="right"
          >
            <v-table-head-cell
                :order-by="`${metric.id}_${i}`"
                center
                sort
                sort-column
            >
              {{ channels[`channels${i}`].join(', ') }}
            </v-table-head-cell>
          </vue-item>
          <vue-item
              :key="`${metric.id}_diff`"
              align="right"
          >
            <v-table-head-cell
                :order-by="`${metric.id}_diff`"
                center
                sort
                sort-column
            >
              Дельта разница
            </v-table-head-cell>
          </vue-item>
          <vue-item
              :key="`${metric.id}_diff_prc`"
              align="right"
          >
            <v-table-head-cell
                :order-by="`${metric.id}_diff_prc`"
                center
                sort
                sort-column
            >
              Дельта, x
            </v-table-head-cell>
          </vue-item>
        </template>
      </vue-line>
    </template>
    <template #body>
      <v-comparison-table-row
          v-for="(item, i) of items"
          :key="i"
          :data="item"
          :metrics="metrics"
          :url-filters="urlFilters"

      />
      <v-comparison-table-row
          v-if="controlGroup"
          :data="controlGroup"
          :metrics="metrics"
          :url-filters="urlFilters"
          control-group
      />
    </template>
  </vue-table>
</template>

<script>
import comparisonTableRow from './comparisonTableRow'
import tableHeadCell from '@/components/tableHeadCell/tableHeadCell'

export default {
  name: 'comparisonTable',

  components: {
    'v-comparison-table-row': comparisonTableRow,
    'v-table-head-cell': tableHeadCell
  },

  props: {
    items: {
      type: Array,
      default: () => ([])
    },

    metrics: {
      type: Object,
      default: () => ({})
    },

    channels: {
      type: Object,
      default: () => ({})
    },

    urlFilters: {
      type: Object,
      default: () => ({})
    },

    controlGroup: {
      type: Object,
      default: undefined
    }
  },

  computed: {
    metricCellCount() {
      let count = 0

      for (let metric in this.metrics) {
        count += 1
      }

      return count * 4
    }
  }
}
</script>
