var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('v-page-title',{staticClass:"_border-bottom _mb-0",attrs:{"primary":_vm.title.primary,"secondary":_vm.title.secondary}}),(_vm.viewPage)?[(_vm.preloader.filters)?_c('v-preloader',{attrs:{"static":"","clear":""}}):_vm._e(),_c('v-filters',_vm._b({directives:[{name:"show",rawName:"v-show",value:(!_vm.preloader.filters),expression:"!preloader.filters"}],on:{"update-url":_vm.updateUrl,"toggle-chart":function($event){_vm.showChart ^= true},"finish-filters-loading":function($event){_vm.preloader.segments = false}}},'v-filters',{
          urlFilters: _vm.urlFilters,
          showChart: !!_vm.showChart,
          metrics: _vm.metrics,
          devices: _vm.devices,
          dateFilters: _vm.dateFilters,
          regions: _vm.regions,
          activeMetrics: _vm.activeMetrics,
          availableDateTo: _vm.availableDateTo,
          availableDateFrom: _vm.availableDateFrom,
          isMixedSegments: true
        },false)),(!_vm.segmentsData.length && !_vm.preloader.data)?_c('v-error',{attrs:{"title":"Сегменты не выбраны","subtitle":"Выберите сегменты для сравнения","show-links":false}}):(!_vm.preloader.filters)?_c('div',{staticClass:"dashboard-wrapper _border-none _mt-32"},[(_vm.showChart)?_c('v-container',{staticClass:"comparison-chart dashboard-block _mb-32",attrs:{"has-data":!!_vm.segmentsData.length,"title":"Сравнение метрик по каналам","load":_vm.preloader.data}},[_c('v-comparison-chart',{attrs:{"items":_vm.segmentsData,"metrics":_vm.activeMetrics,"channels":_vm.channelsSelected,"control-group":_vm.controlGroup}})],1):_vm._e(),_c('v-container',{staticClass:"dashboard-block _mb-32 _ha",attrs:{"has-data":!!_vm.segmentsData.length,"load":_vm.preloader.data},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('p',{staticClass:"metrics"},[_vm._v(_vm._s(_vm.tableTitle))])]},proxy:true},{key:"head-middle",fn:function(){return [_c('p',{staticClass:"total"},[_vm._v("Всего: "),_c('span',[_vm._v(_vm._s(_vm._f("formatCount")(_vm.segmentsData.length)))])])]},proxy:true}],null,false,2230119407)},[_c('v-comparison-table',{attrs:{"items":_vm.segmentsData,"metrics":_vm.activeMetrics,"channels":_vm.channelsSelected,"url-filters":_vm.urlFilters,"control-group":_vm.controlGroup}})],1)],1):_vm._e()]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }