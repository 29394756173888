var render = function render(){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"tree-node"},[_c('div',{class:[
      'tree-node__title',
      {
        _selectable: _vm.metricActive.length !== 2 && !_vm.data.items,
        _active: !_vm.multiple && _vm.active,
      }
    ]},[_c('div',{class:['tree-node__title-wrapper', {'_not-selectable': (_vm.metricActive.length === 2 && !_vm.data.items) || (_vm.active && _vm.metricActive.length === 1)}],style:(`padding-left: ${_vm.level * 16}px`),on:{"click":_vm.clickNode}},[(_vm.data.items)?_c('svg',{class:['tree-node__arrow', { '_open': _vm.activeList } ],attrs:{"viewBox":"0 0 24 24","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M16.59 8.59L12 13.17L7.41 8.59L6 10L12 16L18 10L16.59 8.59Z"}})]):_vm._e(),_c('span',{class:['tree-node__title-text', {'_pl-32': !_vm.data.items}]},[_vm._v(_vm._s(_vm.data.name))])]),(_vm.multiple && !_vm.data.items)?_c('v-checkbox',{staticClass:"tree-node__checkbox",attrs:{"value":_vm.active,"disabled":(!_vm.active && _vm.metricActive.length === 2) || (_vm.active && _vm.metricActive.length === 1)},on:{"input":function($event){return _vm.$emit('select', _vm.data.id)}}}):_vm._e()],1),(_vm.showList)?_c('ul',{directives:[{name:"show",rawName:"v-show",value:(_vm.activeList),expression:"activeList"}]},[(_vm.data.items)?_vm._l((_vm.data.items),function(item,i){return _c('v-tree-node',_vm._b({key:i,on:{"open":_vm.open,"select":function($event){return _vm.$emit('select', item.id)}}},'v-tree-node',{
          level: _vm.level + 1,
          data: item,
          active: Array.isArray(_vm.metricActive) ? _vm.metricActive.includes(item.id) : _vm.metricActive === item.id,
          metricActive: _vm.metricActive,
          multiple: _vm.multiple
        },false))}):_vm._e()],2):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }