<template>
  <li class="tree-node">
    <div :class="[
        'tree-node__title',
        {
          _selectable: metricActive.length !== 2 && !data.items,
          _active: !multiple && active,
        }
      ]"
    >
      <div
          :class="['tree-node__title-wrapper', {'_not-selectable': (metricActive.length === 2 && !data.items) || (active && metricActive.length === 1)}]"
          :style="`padding-left: ${level * 16}px`"
          @click="clickNode"
      >
        <svg
            v-if="data.items"
            :class="['tree-node__arrow', { '_open': activeList } ]"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M16.59 8.59L12 13.17L7.41 8.59L6 10L12 16L18 10L16.59 8.59Z" />
        </svg>

        <span :class="['tree-node__title-text', {'_pl-32': !data.items}]">{{ data.name }}</span>
      </div>

      <v-checkbox
          v-if="multiple && !data.items"
          class="tree-node__checkbox"
          :value="active"
          :disabled="(!active && metricActive.length === 2) || (active && metricActive.length === 1)"
          @input="$emit('select', data.id)"
      />
    </div>

    <ul
        v-if="showList"
        v-show="activeList"
    >
      <template v-if="data.items">
        <v-tree-node
          v-for="(item, i) in data.items"
          :key="i"
          v-bind="{
            level: level + 1,
            data: item,
            active: Array.isArray(metricActive) ? metricActive.includes(item.id) : metricActive === item.id,
            metricActive,
            multiple
          }"
          @open="open"
          @select="$emit('select', item.id)"
        />
      </template>
    </ul>
  </li>
</template>

<script>
export default {
  name: 'v-tree-node',

  props: {
    data: {
      type: Object,
      default: null
    },

    level: {
      type: Number,
      default: 1
    },

    active: {
      type: Boolean,
      default: false
    },

    metricActive: {
      type: [String, Array],
      default: ''
    },

    multiple: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      activeList: false
    }
  },

  computed: {
    showList () {
      return this.data.items && this.data.items.length
    }
  },

  created () {
    this.activeList = this.data.items && Array.isArray(this.data.items) && this.data.items.some(item => Array.isArray(this.metricActive) ? this.metricActive.includes(item.id) : item.id === this.metricActive)
  },

  methods: {
    toggleList () {
      this.activeList ^= true
    },

    open () {
      if (!this.activeList) {
        this.activeList = true
        this.$emit('open')
      }
    },

    clickNode () {
      if (!this.multiple && !this.data.items) {
        this.$emit('select', this.data.id)
      } else if (this.data.items) {
        this.toggleList()
      }
    }
  }
}
</script>
