import {mapState, mapMutations, mapActions, mapGetters} from 'vuex'
import {isEqual} from 'underscore'
import {findOptions, roundNumber, getSegmentQueryParams} from '@/function'

import {NOTIFY_STATUS, DATE_FORMAT} from '@/const'

import updateUrl from '@/mixins/updateUrl'
import regionFilter from '@/mixins/regionFilter'

import filters from './components/filters'
import container from '@/services/components/container'
import comparisonChart from './components/comparisonChart'
import comparisonTable from './components/comparisonTable'
import error from '@/services/errors/block'

const PERIOD_COUNT = ['one']

const DEFAULT_CHANNELS = ['cpc', 'organic']

export default {
    name: 'compareChannels',

    metaInfo () {
        return {
            title: `${this.title.primary}: ${this.title.secondary} / ${this.activeProjectData.label} (${this.activeProjectData.id})`
        }
    },

    mixins: [
      updateUrl,
      regionFilter
    ],

    components: {
        'v-filters': filters,
        'v-container': container,
        'v-comparison-chart': comparisonChart,
        'v-comparison-table': comparisonTable,
        'v-error': error
    },

    data () {
        return {
            title: {
                primary: 'BI',
                secondary: 'Compare channels'
            },
            preloader: {
                filters: true,
                data: true,
                segments: true
            },
            showChart: true,
            dateDefault: {
                from: +this.$moment.utc().subtract(8, 'days').startOf('day').format('x'),
                to: +this.$moment.utc().subtract(1, 'days').startOf('day').format('x')
            },
            availableDateTo: +this.$moment.utc().startOf('day').subtract(1, 'days').format('x'),
            availableDateFrom: +this.$moment.utc('2018-01-01').startOf('day').format('x')
        }
    },

    computed: {
        ...mapState({
          activeProjectData: state => state.active_project_data,
          segmentTreeCatalog: state => state.segments.catalog.dataDF,
          segmentTreeCustom: state => state.segments.catalog.dataDFCustom,
          trafficConditions: state => state.trafficConditions,
          data: state => state.bi.compareChannels.data,
          customData: state => state.bi.compareChannels.customData,
          summary: state => state.bi.compareChannels.summary,
          trafficSources: state => state.trafficSources
        }),

        ...mapGetters(['filtersDevices']),
        ...mapGetters({
          tableCellsConfig: 'bi/compareChannels/getTableCellsConfig'
        }),

        allTreeSegments () {
          let allSegments = JSON.parse((JSON.stringify(this.segmentTreeCustom)))
          allSegments.items = allSegments.items.concat(this.segmentTreeCatalog.items)
          return allSegments
        },

        viewPage () {
            return this.activeProjectData && Object.keys(this.activeProjectData).length !== 0
        },

        activeProjectId () {
            let id = this.$route.params.activeProject
            return id ? +id : undefined
        },

        urlFilters () {
            let queryParams = {...this.$route.query}
            let filters = {
                date: {
                    one: {
                        from: undefined,
                        to: undefined
                    },
                    two: {
                        from: undefined,
                        to: undefined
                    }
                }
            }

            const initFilters = (query, options, required = false, defaultOption, cycle, optionKey = 'id', number = false, metric = false) => {
                try {
                    if (cycle) {
                        if (query) {
                            if (query && Array.isArray(query)) {
                                return query.map(f => number ? +f : f).filter(f => f !== null && f !== undefined && (metric || (!metric && !isNaN(f))))
                            } else if (typeof query === 'string' && number && !isNaN(+query)) {
                                return [+query]
                            } else if ((typeof query === 'number' && !isNaN(+query)) || typeof query === 'string' && !number) {
                                return [query]
                            } else {
                                return defaultOption || []
                            }
                        } else {
                            return defaultOption || []
                        }
                    } else {
                        if (typeof query === 'object' && options) {
                            for (let item of options) {
                                if (query.source && query.source === item.source && query.medium && query.medium === item.medium) {
                                    return item.id
                                }
                            }
                        } else {
                            if ((query || query === 0) && findOptions(query, options, optionKey)) {
                                return query
                            } else if (required) {
                                return defaultOption || options[0].id
                            } else {
                                return null
                            }
                        }
                    }
                } catch (_) {
                    return null
                }
            }

            filters.all_site = queryParams.all_site ? queryParams.all_site === 'true' : true

            filters.control_group = initFilters(queryParams.control_group, this.allTreeSegments.items)

            filters.catalog_ids = initFilters(queryParams.catalog_ids, this.allTreeSegments.items, false, this.data.channels1.items
                .slice(0, 5)
                .filter(item => filters.all_site || !filters.all_site && !filters.control_group || !filters.all_site && filters.control_group && String(filters.control_group) !== item.catalog_id)
                .map(item => String(item.catalog_id)), true).filter(id => !isNaN(+id))

            filters.metric = initFilters(Array.isArray(queryParams.metric) ? queryParams.metric : queryParams.metric ? queryParams.metric : undefined, this.metrics, true, ['count_all', 'transactions'], true, undefined, false, true)

            filters.is_mobile = initFilters(+queryParams.is_mobile, this.devices)

            filters.region_id = initFilters(queryParams.region_id, this.regions, false, undefined, true, 'google_region_id', true)

            filters.order_by = queryParams.order_by || `${filters.metric[0]}_2`

            filters.order_direction = queryParams.order_direction || 'DESC'

            for (let i = 1; i <= 2; i++) {
                filters[`medium_${i}`] = initFilters(queryParams[`medium_${i}`], undefined, true, [DEFAULT_CHANNELS[i - 1]], true, undefined, false, true)
                filters[`traffic_conditions[medium_${i}]`] = initFilters(queryParams[`traffic_conditions[medium_${i}]`], this.trafficConditions, true, 'contains')
            }
            filters.source = initFilters(queryParams.source, undefined, false, [], true, undefined, false, true)
            filters['traffic_conditions[source]'] = initFilters(queryParams['traffic_conditions[source]'], this.trafficConditions)

          filters.traffic_source = initFilters(queryParams.traffic_source, this.trafficSources, true, 'ga4')

            for (let count of PERIOD_COUNT) {
                if (queryParams[`period_${count}_date_from`] && queryParams[`period_${count}_date_to`]) {
                    let dateFrom = +this.$moment.utc(queryParams[`period_${count}_date_from`], DATE_FORMAT.BASE_FORMAT).format('x')
                    let dateTo = +this.$moment.utc(queryParams[`period_${count}_date_to`], DATE_FORMAT.BASE_FORMAT).format('x')

                    if (!dateFrom || dateFrom < this.availableDateFrom || dateFrom > this.availableDateTo) {
                        dateFrom = this.dateDefault.from
                    }
                    if (!dateTo || dateTo < this.availableDateFrom || dateTo > this.availableDateTo) {
                        dateTo = this.dateDefault.to
                    }

                    filters.date[count] = {
                        from: dateFrom || dateTo,
                        to: dateTo || dateFrom
                    }
                } else {
                    if (queryParams[`period_${count}_date_to`]) {
                        let dateTo = +this.$moment.utc(queryParams[`period_${count}_date_to`], DATE_FORMAT.BASE_FORMAT).format('x')

                        if (!dateTo || dateTo < this.availableDateFrom || dateTo > this.availableDateTo) {
                            dateTo = this.dateDefault.to
                        }

                        let dateFrom = +this.$moment.utc(dateTo, 'x').subtract(7, 'days').format('x')

                        if (dateFrom < this.availableDateFrom) {
                            dateFrom = this.dateDefault.from
                        }

                        filters.date[count] = {
                            from: dateFrom,
                            to: dateTo
                        }
                    } else if (queryParams[`period_${count}_date_from`]) {
                        let dateFrom = +this.$moment.utc(queryParams[`period_${count}_date_from`], DATE_FORMAT.BASE_FORMAT).format('x')
                        if (!dateFrom || dateFrom < this.availableDateFrom || dateFrom > this.availableDateTo) {
                            dateFrom = this.dateDefault.from
                        }

                        let dateTo = +this.$moment.utc(dateFrom, 'x').add(7, 'days').format('x')
                        if (dateTo > this.availableDateTo) {
                            dateTo = this.dateDefault.to
                        }

                        filters.date[count].from = dateFrom

                        if (dateTo > +this.$moment.utc().endOf('day').format('x')) {
                            filters.date[count].to = +this.$moment.utc().startOf('day').format('x')
                        } else {
                            filters.date[count].to = dateTo
                        }
                    } else if (count === 'one') {
                        filters.date[count] = {
                            from: this.dateDefault.from,
                            to: this.dateDefault.to
                        }
                    } else if (count === 'two') {
                        let dateDefault = +this.$moment.utc().subtract(9, 'days').startOf('day').format('x')
                        filters.date[count] = {
                            from: +this.$moment.utc(dateDefault, 'x').subtract(7, 'days').format('x'),
                            to: dateDefault
                        }
                    }
                }
            }

            return filters
        },

        metrics () {
            let metrics = []

            this.tableCellsConfig.forEach(section => {
                metrics.push({...section, parent_id: '0'})
                section.items.forEach(metric => {
                    metrics.push({...metric, parent_id: section.id})
                })
            })

            return metrics
        },

        dateFilters () {
            let dateFilters = {
                one: {
                    from: undefined,
                    to: undefined
                },
                two: {
                    from: undefined,
                    to: undefined
                }
            }

            const urlFiltersDate = {...this.urlFilters.date}

            for (let count of PERIOD_COUNT) {
                try {
                    if (urlFiltersDate[count] && urlFiltersDate[count].from) {
                        dateFilters[count].from = this.$moment(urlFiltersDate[count].from).format(DATE_FORMAT.BASE_FORMAT)
                    }

                    if (urlFiltersDate[count] && urlFiltersDate[count].to) {
                        dateFilters[count].to = this.$moment(urlFiltersDate[count].to).format(DATE_FORMAT.BASE_FORMAT)
                    }
                } catch (_) {
                }
            }

            return {
                ...dateFilters,
                comparison: !!dateFilters.two.from && !!dateFilters.two.to && (dateFilters.one.from !== dateFilters.two.from || dateFilters.one.to !== dateFilters.two.to)
            }
        },

        segmentsData () {
          let items = this.mixedSegments.channels1.filter(item => this.urlFilters.catalog_ids.includes(String(item.catalog_id)))
              .map(item => {
                  const secondItem = this.mixedSegments.channels2.find(el => el.catalog_id === item.catalog_id) || {}

                  let newItem = {
                      id: item.catalog_id,
                      name: item.url_title
                  }

                  this.urlFilters.metric.forEach(metric => {
                      const channels1 = item[metric] || 0
                      const channels2 = secondItem[metric] || 0
                      newItem[`${metric}_1`] = channels1
                      newItem[`${metric}_2`] = channels2
                      newItem[`${metric}_diff`] = roundNumber(channels1 - channels2, 2)
                      newItem[`${metric}_diff_prc`] = this.getDiffPrc(channels1, channels2)
                  })

                  return newItem
              })

          if (this.urlFilters.order_by && this.urlFilters.order_direction) {
              const noValue = items.filter(item => item[this.urlFilters.order_by] === undefined)
              const hasValue = items.filter(item => item[this.urlFilters.order_by] !== undefined)

              items = hasValue.sort((a, b) => {
                  if (this.urlFilters.order_direction === 'ASC') {
                      return a[this.urlFilters.order_by] - b[this.urlFilters.order_by]
                  } else {
                      return b[this.urlFilters.order_by] - a[this.urlFilters.order_by]
                  }
              })
                  .concat(noValue)
          }

          return items
        },

        mixedSegments () {
          return {
            channels1: this.catalogSegments.channels1.concat(this.customSegments.channels1),
            channels2: this.catalogSegments.channels2.concat(this.customSegments.channels2)
          }
        },

        customSegments () {
          if (!this.customData.channels1.hasData && !this.customData.channels2.hasData) {
            return {
              channels1: [],
              channels2: []
            }
          }

          return {
            channels1: this.customData.channels1.items.map((item) => ({
              ...item,
               catalog_id: item.name_hash,
               url_title: item.name
              })),
            channels2: this.customData.channels2.items.map((item) => ({
              ...item,
              catalog_id: item.name_hash,
              url_title: item.name
            }))
          }
        },

        catalogSegments () {
          if (!this.data.channels1.hasData && !this.data.channels2.hasData) {
            return {
              channels1: [],
              channels2: []
            }
          }

          return {
            channels1: [...this.data.channels1.items],
            channels2: [...this.data.channels2.items]
          }
        },

        activeMetrics () {
            try {
                let metrics = {}

                this.metrics
                    .filter(metric => this.urlFilters.metric.includes(metric.id))
                    .forEach(metric => {
                        metrics[metric.id] = metric
                    })

                return metrics
            } catch (_) {
                return undefined
            }
        },

        tableTitle () {
            let title = this.activeMetrics[this.urlFilters.metric[0]].name

            if (this.urlFilters.metric[1]) {
                title += ` / ${this.activeMetrics[this.urlFilters.metric[1]].name}`
            }

            return title
        },

        channelsSelected () {
            return {
                channels1: this.urlFilters.medium_1,
                channels2: this.urlFilters.medium_2
            }
        },

        controlGroup () {
            let item

            if (this.urlFilters.all_site) {
                let item = {
                    id: 0,
                    name: 'Весь сайт'
                }

                this.urlFilters.metric.forEach(metric => {
                    const channels1 = this.summary.channels1.data[metric] || 0
                    const channels2 = this.summary.channels2.data[metric] || 0
                    item[`${metric}_1`] = channels1
                    item[`${metric}_2`] = channels2
                    item[`${metric}_diff`] = roundNumber(channels1 - channels2, 2)
                    item[`${metric}_diff_prc`] = this.getDiffPrc(channels1, channels2)
                })

                return item
            } else if (this.segmentsData.length && Array.isArray(this.segmentsData) && this.urlFilters.control_group) {
                item = this.mixedSegments.channels1.find(item => String(item.catalog_id) === String(this.urlFilters.control_group)) || {}
                const secondItem = this.mixedSegments.channels2.find(el => el.catalog_id === item.catalog_id) || {}
                let newItem = {
                    id: item.catalog_id,
                    name: item.url_title
                }

                this.urlFilters.metric.forEach(metric => {
                    const channels1 = item[metric] || 0
                    const channels2 = secondItem[metric] || 0
                    newItem[`${metric}_1`] = channels1
                    newItem[`${metric}_2`] = channels2
                    newItem[`${metric}_diff`] = roundNumber(channels1 - channels2, 2)
                    newItem[`${metric}_diff_prc`] = this.getDiffPrc(channels1, channels2)
                })

                return newItem
            }

            return item
        },

        filtersLoaded () {
            return !this.preloader.filters && !this.preloader.segments
        },

        devices() {
            return this.filtersDevices
        },

        regionSelected() {
            return this.getRegionSelected(this.urlFilters.region_id)
        },
    },

    watch: {
        async $route (to, from) {
            const qt = to.query
            const qf = from.query

            if (
                !isEqual(qt.metric, qf.metric) ||
                qt.order_by !== qf.order_by ||
                qt.order_direction !== qf.order_direction ||
                (qt.all_site === 'false' && qt.all_site !== qf.all_site) ||
                (qt.all_site === 'true' && this.summary.channels1.hasData && this.summary.channels2.hasData && qt.all_site !== qf.all_site) ||
                (qt.control_group !== qf.control_group && this.urlFilters.catalog_ids.includes(qt.control_group)) ||
                (!qt.control_group && qt.control_group !== qf.control_group)
            ) {
                return
            } else if (!isEqual(qt.catalog_ids, qf.catalog_ids) && !qt.catalog_ids.length) {
                this.clearData()
                return
            }

            this.setCancelRequests(true)
            await this.init()
        },

        async filtersLoaded (val) {
            if (val) {
                await this.init(!this.urlFilters.catalog_ids.length)
            }
        }
    },

    async created () {
        this.clearData()
        this.clearSummary()

        try {
            await this.getFilters({
                query: {
                    project_id: this.activeProjectId,
                    skip_regionality: 1
                }
            })
        } catch ({message}) {
            this.$notify({title: message, type: NOTIFY_STATUS.ERROR})
        } finally {
            this.preloader.filters = false
        }

        await this.getRegions()
    },

    methods: {
        ...mapMutations({
            clearData: 'bi/compareChannels/clearData',
            setCancelRequests: 'setCancelRequests',
            clearSummary: 'bi/compareChannels/clearSummary'
        }),

        ...mapActions({
            getFilters: 'getFilters',
            getData: 'bi/compareChannels/getData',
            getUserSegments: 'bi/compareChannels/getUserSegments',
        }),

        async init (defaultSegments) {
            if (!this.preloader.data) {
                this.preloader.data = true
            }

            let filters = {}

            if (defaultSegments) {
                filters.limit = 5
                filters.order_by = this.urlFilters.metric[0]
                filters.sort_direction = 'DESC'
            }

            const requests = []

            for (let i = 1; i <= 2; i++) {
                requests.push(this.requestData({
                            ...filters,
                            medium: this.urlFilters[`medium_${i}`],
                            'traffic_conditions[medium]': this.urlFilters[`traffic_conditions[medium_${i}]`]
                        },
                        i)
                )
            }

            if (this.urlFilters.all_site) {
                for (let i = 1; i <= 2; i++) {
                    requests.push(this.requestData({
                                summary: 1,
                                medium: this.urlFilters[`medium_${i}`],
                                'traffic_conditions[medium]': this.urlFilters[`traffic_conditions[medium_${i}]`]
                            },
                            i)
                    )
                }
            }

            try {
                await Promise.all(requests)
            } catch ({message}) {
                this.$notify({type: NOTIFY_STATUS.ERROR, title: message})
            } finally {
                this.preloader.data = false
            }
        },

        async requestData (filters = {}, index) {
            if (this.activeProjectId && this.dateFilters.one.from && this.dateFilters.one.to) {
                let query = {
                    project_id: this.activeProjectId,
                    skip_regionality: 1,
                    v2: 1,
                    catalog_id: '0',
                    is_mobile: this.urlFilters.is_mobile === 0 ? '0' : this.urlFilters.is_mobile,
                    source: this.urlFilters.source,
                    'traffic_conditions[source]': this.urlFilters['traffic_conditions[source]'],
                  traffic_source: this.urlFilters.traffic_source,
                    ...filters
                }

                if (this.regionSelected) {
                    query.region_id = this.regionSelected.map(region => region.yandex_region_id).filter(id => id)
                    query.google_region_id = this.regionSelected.map(region => region.google_region_id)
                    query.need_geo = this.regionSelected && this.regionSelected.length ? 1 : undefined
                }

                if (this.dateFilters.comparison) {
                    query.period_one_date_from = this.dateFilters.one.from
                    query.period_one_date_to = this.dateFilters.one.to
                    query.period_two_date_from = this.dateFilters.two.from
                    query.period_two_date_to = this.dateFilters.two.to
                } else {
                    query.date_from = this.dateFilters.one.from
                    query.date_to = this.dateFilters.one.to
                }

                let requests = []

                if (filters.summary) {
                  requests.push(this.getData({
                    query: {
                      ...query,
                      catalog_ids: undefined
                    },
                    index
                  }))
                } else {
                  if (filters.limit) {
                    requests.push(this.getData({
                      query: {
                        ...query,
                        catalog_filter_mode: 'as_is',
                      },
                      index
                    }))
                  }

                  const {segments, catalog_ids} = getSegmentQueryParams(this.segmentTreeCustom, this.segmentTreeCatalog, this.urlFilters)
                  if (catalog_ids) {
                    requests.push(this.getData({
                      query: {
                        ...query,
                        catalog_filter_mode: 'as_is',
                        catalog_ids
                      },
                      index
                    }))
                  }

                  if (segments) {
                    delete query.catalog_id

                    requests.push(this.getUserSegments({
                      query: {
                        ...query,
                        segments
                      },
                      index
                    }))
                  }
                }

                await Promise.all(requests)
            }
        },

        getDiffPrc (channels1, channels2) {
            return channels2 === 0 ? 0 : roundNumber(channels1 / channels2, 2)
        }
    }
}
