var render = function render(){var _vm=this,_c=_vm._self._c;return _c('vue-line',{class:{'_control-group': _vm.controlGroup}},[_c('vue-item',[(_vm.controlGroup)?_c('span',{staticClass:"label"},[_vm._v(" Контрольная группа: ")]):_vm._e(),_vm._v(" "+_vm._s(_vm.data.name)+" ")]),_vm._l((_vm.metrics),function(metric){return [_vm._l((2),function(i){return [_c('vue-item',{attrs:{"align":"right"}},[_vm._v(" "+_vm._s(_vm._f("formatCount")(_vm.data[`${metric.id}_${i}`]))+_vm._s(metric.additional)+" ")])]}),_c('vue-item',{attrs:{"align":"right"}},[_c('vue-element',{staticClass:"metric__diff",attrs:{"type":"label","data":_vm.data[`${metric.id}_diff`],"label-note":metric.additional,"locales":"ru-RU"}})],1),_c('vue-item',{attrs:{"align":"right"}},[(_vm.data[`${metric.id}_diff_prc`] || _vm.data[`${metric.id}_diff_prc`] === 0)?[_vm._v(" "+_vm._s(_vm._f("formatCount")(_vm.data[`${metric.id}_diff_prc`]))+" ")]:_vm._e()],2)]}),_c('vue-item',{attrs:{"align":"center"}},[(!_vm.controlGroup)?_c('div',{staticClass:"buttons"},[_c('v-button',{directives:[{name:"tippy",rawName:"v-tippy"}],attrs:{"size":"small","type":"text-base","padding-right":"6px","padding-left":"6px","to":{query: _vm.routeCG.query},"content":"Сделать контрольной группой"}},[_c('icon',{attrs:{"name":"kg","fill":"#9d99aa"}})],1),_c('v-button',_vm._b({directives:[{name:"tippy",rawName:"v-tippy"}]},'v-button',{
          size: 'small',
          type: 'text-base',
          paddingLeft: '6px',
          paddingRight: '6px',
          to: { query: _vm.routeDelete.query },
          content: 'Убрать из сравнения'
        },false),[_c('icon',_vm._b({},'icon',{
            name: 'delete',
            fill: '#9D99AA'
          },false))],1)],1):_vm._e()])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }